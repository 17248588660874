import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { mapJobAdDTO2Model } from '../../../../../DTO/Assessments/JobAdDTO';
import { useAssessmentClient, useJobAdClient, useSettingsClient } from '../../../../../Hooks/ClientHooks';
import { Currency } from '../../../../../Models/Currency';
import ProcessingState from '../../../../../Models/ProcessingState';
import RoutePath from '../../../../../Routing/RoutePath';
import { JobAd } from '../../Models/JobAd';
import { JobDetails } from '../../Models/JobDetails';
import {
    EditRecruitmentContextState,
    DefaultEditRecruitmentContextState,
    mapEditRecruitmentContextState2Dto,
} from './EditRecruitmentContextState';
import { WritingTone } from '../../../../../Models/WritingTone';
import useTalentMeshHistory from '../../../../../Routing/useTalentMeshHistory';
import { mapJobDetailsDTO2Model } from '../../Steps/JobDetailsStep/JobDetailsStepUtils';

interface IEditRecruitmentContext extends EditRecruitmentContextState {
    currencies: Currency[];
    gettingDataProcessingState: ProcessingState;

    setContextState: (value: EditRecruitmentContextState) => void;
    setJobAd: (value: JobAd) => void;
    setProcessingState: (value: ProcessingState) => void;

    updateJobDetails: (newJobDetails: JobDetails) => Promise<void>;
    updateRecruitmentAsync: (values: JobAd) => Promise<void>;

    generateJobAdAsync: () => Promise<void>;
    improveJobAdAsync: (tone: WritingTone, title: string, jobDescription: string, companyDesc: string) => Promise<void>;
    generateExperienceCriteria: () => Promise<void>;

    closeDialog: () => void;
}

const EditRecruitmentContext = createContext<IEditRecruitmentContext | undefined>(undefined);

const EditRecruitmentContextProvider = EditRecruitmentContext.Provider;

interface EditRecruitmentProviderProps {
    children: ReactNode;
}

export const EditRecruitmentProvider = ({ children }: EditRecruitmentProviderProps): JSX.Element => {
    const history = useTalentMeshHistory();
    const location = useLocation();
    const jobAdId = new URLSearchParams(location.search).get('jobAdId');
    if (jobAdId === null) {
        history.replace(RoutePath.Error);
    }

    const [state, setState] = useState<EditRecruitmentContextState>(DefaultEditRecruitmentContextState);
    const { recruitmentId, jobDetails, jobAd, processingState, regenerateAtStep2, showErrorDialog } = state;

    const settingsClient = useSettingsClient();
    const jobAdClient = useJobAdClient();
    const assessmentClient = useAssessmentClient();

    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [gettingDataProcessingState, setGettingDataProcessingState] = useState(ProcessingState.Processing);

    const setProcessingState = (value: ProcessingState): void => {
        setState((prevState) => {
            return {
                ...prevState,
                processingState: value,
            };
        });
    };

    const setContextState = (value: EditRecruitmentContextState): void => {
        setState(value);
    };

    useEffect(() => {
        const loadDataAsync = async () => {
            try {
                const currenciesList = await settingsClient.getCurrenciesAsync();
                setCurrencies(currenciesList);

                const dto = await jobAdClient.getJobAdAsync(jobAdId ?? '');
                setContextState({
                    ...state,
                    recruitmentId: dto.recruitmentId,
                    jobDetails: mapJobDetailsDTO2Model(dto.jobDetails, currenciesList),
                    jobAd: { ...mapJobAdDTO2Model(dto) },
                });

                setGettingDataProcessingState(ProcessingState.Success);
            } catch (error) {
                setGettingDataProcessingState(ProcessingState.Error);
            }
        };
        loadDataAsync();
    }, []);

    const setJobDetails = (value: JobDetails): void => {
        setState((prevState) => {
            return {
                ...prevState,
                jobDetails: value,
            };
        });
    };

    const setJobAd = (value: JobAd): void => {
        setState((prevState) => {
            return {
                ...prevState,
                jobAd: value,
            };
        });
    };

    const updateJobDetails = async (newJobDetails: JobDetails): Promise<void> => {
        setJobDetails(newJobDetails);
    };

    const updateRecruitmentAsync = async (values: JobAd): Promise<void> => {
        setProcessingState(ProcessingState.Processing);
        try {
            const dto = mapEditRecruitmentContextState2Dto({ ...state, jobAd: values }, values.status);
            await assessmentClient.updateRecruitmentAsync(dto);

            setProcessingState(ProcessingState.Success);
        } catch (e) {
            setProcessingState(ProcessingState.Error);
            throw e;
        }
    };

    const generateJobAdAsync = async (): Promise<void> => {
        throw new Error('Not implemented');
    };

    const improveJobAdAsync = async (tone: WritingTone, title: string, jobDescription: string, companyDesc: string) => {
        throw new Error('Not implemented');
    };

    const generateExperienceCriteria: IEditRecruitmentContext['generateExperienceCriteria'] = async () => {
        throw new Error('Not implemented');
    };

    const closeDialog = () => {
        setState((prev) => {
            return {
                ...prev,
                showErrorDialog: undefined,
            };
        });
    };

    return (
        <EditRecruitmentContextProvider
            value={{
                recruitmentId,
                jobDetails,
                jobAd,
                processingState,
                regenerateAtStep2,
                currencies,
                gettingDataProcessingState,
                showErrorDialog,

                setContextState,
                setJobAd,
                setProcessingState,
                updateJobDetails,
                updateRecruitmentAsync,
                generateJobAdAsync,
                improveJobAdAsync,
                generateExperienceCriteria,

                closeDialog,
            }}
        >
            {children}
        </EditRecruitmentContextProvider>
    );
};

export function useEditRecruitmentContext() {
    const context = useContext(EditRecruitmentContext);
    if (!context) {
        throw new Error('useEditRecruitmentContext must be used within the EditRecruitmentContext.Provider');
    }
    return context;
}
